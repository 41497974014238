export const PHONE_NUMBER_LENGTH = 10;

/**
 * Ensure string is a valid email address.
 * Must have 3 groups of characters broken up by an "@" and a "."
 * Valid examples: b@c.com, b+1@c.com, b.b@c.com, b-b@c.com
 *
 * @param {string} value
 * @returns {boolean}
 */
export const isValidEmail = value => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);
};

/**
 * Ensure string is a valid phone number.
 * Must be in standard 10-digit format (555-444-3333).
 *
 * @param {string} value
 * @returns {boolean}
 */
export const isValidPhone = value => {
  return /^(\d{3})-(\d{3})-(\d{4})$/.test(value);
};
