import mixpanel from 'mixpanel-browser';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { COOKIES } from 'woop-shared/constants/cookies';
import { setCookie } from 'woop-shared/utils/cookies';
import { getExpiration, getUid } from 'woop-shared/utils/jwt';
import { login } from '@/api/auth';
import { AuthLayout } from '@/components/layouts/auth';
import { Button } from '@/components/shared/button';
import { TextField } from '@/components/shared/text-field';
import { ROUTES } from '@/constants/router';
import { isValidEmail } from '@/utils/form';
import styles from './styles.module.css';

export default function Login() {
  const router = useRouter();

  const [email, setEmail] = useState('');
  const [pass, setPass] = useState('');

  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passError, setPassError] = useState('');
  const [showValidation, setShowValidation] = useState(false);

  useEffect(() => {
    if (!email) return setEmailError('Email is required');
    if (!isValidEmail(email)) return setEmailError('Invalid email');
    setEmailError('');
    if (pass.length < 8) return setPassError('Password must be at least 8 characters long');
    setPassError('');
  }, [email, pass]);

  const handleSubmit = () => {
    if (!email) {
      setShowValidation(true);
      return setEmailError('Email is required');
    }
    if (!isValidEmail(email)) {
      setShowValidation(true);
      return setEmailError('Invalid email');
    }
    setEmailError('');

    if (pass.length < 8) {
      setShowValidation(true);
      return setPassError('Password must be at least 8 characters long');
    }
    setPassError('');
    setShowValidation(false);
    setLoading(true);
    login(email, pass)
      .then(({ data }) => {
        const expiration = getExpiration(data.token);
        const uid = getUid(data.token);

        if (!expiration || !uid) return;

        setCookie(COOKIES.JWT, data.token, { expiration });
        setCookie(COOKIES.UID, uid, { expiration });

        mixpanel.identify(uid);
        router.push(ROUTES.HOME);
      })
      .catch(() => {
        setStatus('Login failed. Please check your credentials and try again.');
        setLoading(false);
      });
  };

  return (
    <AuthLayout title="Login">
      <div className={styles.container}>
        <h3 className={styles.title}>Welcome to GarageShare™</h3>

        <div className={styles.description}>Enter your email address and password to sign in.</div>

        <div className={styles.input}>
          <TextField
            name="email"
            label="Email address"
            inputType="email"
            save={setEmail}
            value={email}
            isValid={isValidEmail(email)}
            showValidation={showValidation}
            validationText={emailError}
            disabled={loading}
          />

          <TextField
            name="password"
            inputType="password"
            label="Password"
            save={setPass}
            value={pass}
            isValid={!passError}
            showValidation={showValidation}
            validationText={passError}
            disabled={loading}
          />
        </div>

        <Button
          className={styles.button}
          onClick={handleSubmit}
          loading={loading}
          showArrow={true}
          useKeyboard={true}
        >
          Login
        </Button>

        {status && <div className={styles.status}>{status}</div>}

        <div className={styles.footer}>
          <div>Forgot password?</div>
          <Link href={ROUTES.AUTH_PASSWORD_REQUEST}>Request a reset link</Link>
        </div>
      </div>
    </AuthLayout>
  );
}
